#featured-products_block_center {
	position: relative;
}

#featured-products_block_center li {
	margin-right:10px;
	padding:10px 0;
	width:250px;
	float: left;
}
#featured-products_block_center .block_content {
	overflow: hidden;
}
#featured-products_block_center li.last_item_of_line  {margin-right:0;}
#featured-products_block_center .s_title_block,  #featured-products_block_center h5 {
	padding-top:5px;
	height:30px;
	min-height:30px;
	max-height:30px;
	overflow: hidden;
	font-size:12px;
	color:#222;
	padding-bottom: 0;
	font-weight:bold;
	text-align: center;
}

#featured-products_block_center .product_image {
	display:block;
	position:relative;
	overflow:hidden
}
#featured-products_block_center .product_image span.new {
	display: block;
	position: absolute;
	top: 15px;
	right:-30px;
	padding: 1px 4px;
	width: 101px;
	font-size:10px;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-o-transform:rotate(45deg);
	-ms-transform: rotate(45deg);
	background-color: #990000;
	transform: rotate(45deg);  /* Newer browsers */
}

#featured-products_block_center .product_desc {
	height: 45px;
	min-height:45px;
	max-height: 45px;
	overflow: hidden;
}
#featured-products_block_center .product_desc,
#featured-products_block_center .product_desc a {
	color:#666
}
#featured-products_block_center .lnk_more {
	display:inline;
	padding-right:10px;
	font-weight:bold;
	font-size:10px;
	color:#0088cc;
	background:url(../img/arrow_right_1.png) no-repeat 100% 3px;
}
#featured-products_block_center .price_container {
	margin-top:10px;
	padding:0;
	text-align: center;
}
#featured-products_block_center .price {
	font-weight:bold;
	font-size:14px;
}
#featured-products_block_center li .ajax_add_to_cart_button {display:none;}
#featured-products_block_center li span.exclusive {display:none;}


#featured-products_scroll_left, #featured-products_scroll_right {
	background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	width: 50px;
	text-indent: -1000px;
	overflow: hidden;
	outline: none;
	z-index: 1;
	&:after {
		content: "";
		width: 0;
		border-top: 20px solid transparent;
		border-bottom: 20px solid transparent;
		border-right: 20px solid #000;
		position: absolute;
		top: 50%;
		left: 0;
	}
}

#featured-products_scroll_right {
	background: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0));
	right: 0;
	left: auto;
	&:after {
		border-right: none;
		border-left: 20px solid #000;
		right: 0;
		left: auto;
	}
}
